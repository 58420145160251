import type { FC, ReactNode } from 'react';
import React, { useContext, useReducer, createContext, useMemo } from 'react';
import type { Address } from '../../components/commercetools-ui/organisms/checkout/components/steps/sections/addresses/types';

const initialData = {
    addressType: 'billing',
    streetName: '',
    additionalAddressInfo: '',
    line1: '',
    line2: '',
    postalCode: '',
    city: '',
    country: 'GB',
    phone: '',
    firstName: '',
    lastName: '',
    region: ''
};

interface LoqateProviderProps {
    children: ReactNode;
}

export interface LoqateContextShape {
    loqateData?: any;
    loqateDispatch?: any;
}

const LoqateContext = createContext<LoqateContextShape>({
    loqateData: {},
    loqateDispatch() {}
});
interface LoqateAction {
    type: LoqateEnums;
    payload?: Address | Record<string, string>;
}
interface LoqateState {
    streetName?: string;
    additionalAddressInfo?: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    region?: string;
}

enum LoqateEnums {
    UPDATE_LOQATE_FIELD = 'UPDATE_LOQATE_FIELD',
    UPDATE_FIELD = 'UPDATE_FIELD',
    RESET_FIELDS = 'RESET_FIELDS'
}

const dataReducer = (state: LoqateState, action: LoqateAction) => {
    let updatedFields;
    let payload;
    let updatedField;
    let resetedFields;
    switch (action.type) {
        case LoqateEnums.UPDATE_LOQATE_FIELD:
            updatedFields = {
                ...state,
                line1: action.payload?.line1,
                line2: action.payload?.line2,
                postalCode: action.payload?.postalCode,
                city: action.payload?.city,
                country: action.payload?.country,
                region: action.payload?.region
            };
            return updatedFields;
        case LoqateEnums.UPDATE_FIELD:
            payload = action.payload as Record<string, string>;
            updatedField = { ...state, [payload.name]: payload.value };

            console.log('state', state);
            return updatedField;
        case LoqateEnums.RESET_FIELDS:
            resetedFields = { ...initialData };
            return resetedFields;
        default:
            return state;
    }
};

const LoqateProvider: FC<LoqateProviderProps> = ({ children }) => {
    const [loqateData, loqateDispatch] = useReducer(dataReducer, initialData);

    const value = useMemo(() => ({ loqateData, loqateDispatch }), [loqateData, loqateDispatch]);

    return <LoqateContext.Provider value={value}>{children}</LoqateContext.Provider>;
};

export default LoqateProvider;

export const useLoqate = () => useContext(LoqateContext);
